<template>
  <div class="sidebar pb-2 pb-md-16">
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel class="sidebar-box">
        <v-expansion-panel-header class="sidebar-header"
          >{{ $t(`support.items.${rootMenu}.title`) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ul class="sidebar-list">
            <li v-for="(item, i) in items" :key="i">
              <a @click.prevent="itemChanged(item.key)">
                {{ $t(`support.items.${rootMenu}.menu.${item.key}.text`) }}
              </a>
            </li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    rootMenu: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemChanged: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      panel: [0],
    };
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
